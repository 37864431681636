@use "sass:color";

.form_field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding: 1.5rem 1rem;
  background: var(--rc-wiz-formFieldBackground);
  border-radius: 4px;
  width: calc(100% - 3rem);
  position: relative;
  border: 1px solid transparent;
  transition: border 0.2s ease-in, box-shadow 0.2s ease-in;

  input:not([type="radio"], [type="checkbox"]),
  textarea {
    border-radius: 4px;
    border: none;
    background: var(--rc-wiz-inputBackground);
    height: 28px;
    min-width: 300px;
    font-size: 1rem;
    color: var(--rc-wiz-inputTextColor);

    &:focus {
      outline: 2px solid var(--rc-wiz-primary);
    }
  }

  &.is_valid.highlight:not(.no_border) {
    border: 1px solid var(--rc-wiz-success);
  }

  &.is_not_valid.highlight:not(.no_border) {
    border: 1px solid var(--rc-wiz-fail);
  }

  &:not(.is_valid):not(.is_not_valid),
  &.is_not_valid.no_border {
    border: 1px solid var(--rc-wiz-formFieldBorder);
  }

  &.RTL {
    direction: rtl;
    text-align: right;
  }
}

.input_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.form_field_label {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  color: var(--rc-wiz-textColor);
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  transition: background 0.25s ease-in, color 0.25s ease-in, fill 0.25s ease-in;

  &.success {
    background: var(--rc-wiz-success);
    color: #fff;
  }

  &.fail {
    background: var(--rc-wiz-fail);

    svg {
      fill: #fff;
    }
  }

  &.warning {
    background: var(--rc-wiz-warning);

    svg {
      fill: #fff;
    }
  }

  &.RTL {
    right: auto;
    left: 1rem;
  }

  &:not(.RTL) {
    right: 1rem;
    left: auto;
  }

  svg {
    width: 70%;
    height: 70%;
  }
}

.asterisk {
  width: 0.7rem;
  height: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.RTL {
    margin-right: 0.5rem;
  }

  &:not(.RTL) {
    margin-left: 0.5rem;
  }

  svg {
    fill: var(--rc-wiz-fail);
    height: 100%;
    width: 100%;
  }
}

.collection_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    margin-right: 0.5rem;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--rc-wiz-textColor);
    padding: 0.5rem;
  }
}
