.form_field_message_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.85rem;
  border-radius: 4px;
  padding: 0.35rem 0.5rem;
  background: var(--rc-wiz-primary);
  transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid var(--rc-wiz-primary);
    position: absolute;
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &.hide {
    visibility: hidden;
    opacity: 0;
  }

  &:not(.RTL)::after {
    left: -6px;
  }

  &.RTL::after {
    right: -6px;
    transform: rotate(180deg);
  }

  &.RTL {
    margin-right: 2rem;
  }

  &:not(.RTL) {
    margin-left: 2rem;
  }

  &.RTL {
    .icon {
      margin-left: 0.2rem;
    }
  }

  &:not(.RTL) {
    .icon {
      margin-right: 0.2rem;
    }
  }

  .icon {
    width: 1rem;
    height: 1rem;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .message {
    display: block;
  }
}