.wizard_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e8e8e8;
  padding-top: 1rem;

  &.RTL {
    direction: rtl;
  }
}

.button_controls {
  display: flex;
  align-items: center;
  justify-content: center;

  &.RTL {
    margin-right: auto;
    direction: rtl;
  }

  &:not(.RTL) {
    margin-left: auto;
  }
}

.message {
  display: block;
  color: var(--rc-wiz-textColor);
}

.button {
  border: 0;
  background: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: normal;
  background: var(--rc-wiz-primary);
  color: #fff;
  padding: 0.25rem;
  cursor: pointer;
  min-width: 70px;
  min-height: 35px;

  display: grid;
  justify-items: center;
  align-items: center;

  &.back {
    grid-template-columns: 30% 70%;
  }

  &:not(.back) {
    grid-template-columns: 70% 30%;
  }

  &.RTL:not(:last-child) {
    margin-left: 0.5rem;
  }

  &:not(.RTL):not(:last-child) {
    margin-right: 0.5rem;
  }

  &.disabled {
    background: #b5b5b5;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.RTL {
    direction: rtl;
    // border: 1px solid red;
  }

  &.RTL:not(:last-child) {
    // margin-left: 0.5rem;
    // border: 1px solid red;
  }
}

.RTL {
  .btn_icon {
    transform: rotate(180deg);
  }
}

.btn_icon {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
}
